import { ScrapeContext, Stream, flags } from "@movie-web/providers";
import { load } from "cheerio";

import { getM3u8, getSRC } from "./utils";
import { makeSourcerer } from "../../base";

const primewiresBase = "https://ww1.primewires.co";

async function search(ctx: ScrapeContext, title: string) {
  const searchResult: { data: { s: string }[] } = await ctx.proxiedFetcher(
    "/searching/",
    {
      baseUrl: primewiresBase,
      query: {
        q: title,
        limit: "12",
        offset: "0",
      },
    },
  );

  if (searchResult.data.length > 0) {
    return searchResult.data[0].s;
  }

  throw new Error("No search result");
}

async function getStreams(
  htmlString: string,
  ctx: ScrapeContext,
): Promise<Stream[]> {
  const playUrlMatch = htmlString.match(/plyURL="(.*)";/);
  const playUrl = playUrlMatch?.[1];
  if (!playUrl) throw new Error("No palyUrl");

  const page = load(htmlString);

  const mid = page("#mid").attr("data-mid");
  if (!mid) throw new Error("No data-mid");

  const episode = page(".episode")[0].attribs.id.split("-").pop();
  if (!episode) throw new Error("No episode");

  const sourceUrl = await getSRC(2, mid, episode, playUrl, ctx);

  const { finalUrl }: { finalUrl: string } =
    await ctx.proxiedFetcher.full(sourceUrl);

  const hash = new URL(sourceUrl).hash.slice(1);
  const realLocation = new URL(finalUrl).origin;
  const m3u8 = await getM3u8(ctx, hash, realLocation);

  return [
    {
      id: "primary",
      playlist: m3u8,
      type: "hls",
      flags: [flags.CORS_ALLOWED],
      captions: [],
    },
  ];
}

export const primewiresScraper = makeSourcerer({
  id: "primewires",
  name: "Primewires",
  rank: 112,
  flags: [flags.CORS_ALLOWED],
  async scrapeMovie(ctx) {
    // https://ww1.primewires.co/movie/inside-out-2-1630857171/

    if (!ctx.media.title) throw new Error("No title provided");

    const searchResult = await search(ctx, ctx.media.title);

    ctx.progress(30);

    const title = await ctx.proxiedFetcher<string>(`movie/${searchResult}`, {
      baseUrl: primewiresBase,
    });

    ctx.progress(60);

    const stream = await getStreams(title, ctx);

    ctx.progress(90);

    return {
      embeds: [],
      stream,
    };
  },
  async scrapeShow() {
    return {
      embeds: [],
    };
  },
});
