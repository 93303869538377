import axios from "axios";

import {
  UserInfo,
  VipInfo,
  useTokenStore,
  useUserInfoStore,
  useVipInfoStore,
} from "@/stores/user/index.js";

export function getUserInfo(
  token: string,
  setUserInfo: (userInfo: UserInfo) => void,
) {
  const headers = { Authorization: "Bearer ".concat(token) };

  axios
    .get(`${import.meta.env.VITE_API_BASE}/api/user/detail`, {
      headers,
    })
    .then((res) => {
      if (res.data.code === 200 && res.data.errorCode === 0) {
        const userInfoResponse = res.data.data;
        if (userInfoResponse.head === "")
          userInfoResponse.head = "/image/head.png";

        setUserInfo(userInfoResponse);
      }

      if (res.data.errorCode === 40003) {
        (window as any).brapi.login();
      }
    });
}

export function getVipInfo(
  token: string,
  setVipInfo: (vipInfo: VipInfo) => void,
) {
  const headers = { Authorization: "Bearer ".concat(token) };

  axios
    .get(`${import.meta.env.VITE_API_BASE}/api/user/vip/module/3`, {
      headers,
    })
    .then((res) => {
      if (res.data.code === 200 && res.data.errorCode === 0 && res.data.data) {
        setVipInfo(res.data.data);
      }

      if (res.data.errorCode === 40003) {
        (window as any).brapi.login();
      }
    });
}

export function useCheckToken() {
  const setToken = useTokenStore((state) => state.setToken);
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo);
  const setVipInfo = useVipInfoStore((state) => state.setVipInfo);

  const href = window.location.href;
  const index = href.indexOf("?");
  const queryParamsString = index !== -1 ? href.substring(index + 1) : "";
  const searchParams = new URLSearchParams(queryParamsString);
  const tokenParam = searchParams.get("token");

  if (tokenParam) {
    setToken(tokenParam);
    getUserInfo(tokenParam, setUserInfo);
    getVipInfo(tokenParam, setVipInfo);
  }
}

/** 用户是否为 vip */
export function useIsVIP() {
  const vipInfo = useVipInfoStore((state) => state.vipInfo);

  return vipInfo.stats === 1;
}
