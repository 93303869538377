import { EmbedOptions, SourcererOptions } from "@movie-web/providers";

export type MediaScraperTypes = "show" | "movie";

export type Sourcerer = SourcererOptions & {
  type: "source";
  disabled: boolean;
  mediaTypes: MediaScraperTypes[];
};

export function makeSourcerer(state: SourcererOptions): Sourcerer {
  const mediaTypes: MediaScraperTypes[] = [];
  if (state.scrapeMovie) mediaTypes.push("movie");
  if (state.scrapeShow) mediaTypes.push("show");
  return {
    ...state,
    type: "source",
    disabled: state.disabled ?? false,
    mediaTypes,
  };
}

export type Embed = EmbedOptions & {
  type: "embed";
  disabled: boolean;
  mediaTypes: undefined;
};

export function makeEmbed(state: EmbedOptions): Embed {
  return {
    ...state,
    type: "embed",
    disabled: state.disabled ?? false,
    mediaTypes: undefined,
  };
}
