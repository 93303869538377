import {
  ProviderBuilder,
  buildProviders,
  getBuiltinEmbeds,
  getBuiltinSources,
  makeProviders,
  makeStandardFetcher,
  targets,
} from "@movie-web/providers";

import { isExtensionActiveCached } from "@/backend/extension/messaging";
import {
  makeExtensionFetcher,
  makeLoadBalancedSimpleProxyFetcher,
} from "@/backend/providers/fetchers";

import { primewiresScraper } from "./sources/primewires";

const blockedEmbeds = ["voe"];
function addEmbeds(builder: ProviderBuilder): ProviderBuilder {
  const embeds = getBuiltinEmbeds().filter(
    (item) => !blockedEmbeds.includes(item.id),
  );
  let b = builder;

  for (const embed of embeds) {
    b = b.addEmbed(embed);
  }

  return b;
}

const blockedSources = <string[]>[];
function addSources(builder: ProviderBuilder): ProviderBuilder {
  const sources = getBuiltinSources().filter(
    (item) => !blockedSources.includes(item.id),
  );
  let b = builder;

  for (const source of sources) {
    b = b.addSource(source);
  }

  return b;
}

export function getProviders() {
  if (isExtensionActiveCached()) {
    return makeProviders({
      fetcher: makeExtensionFetcher(),
      target: targets.BROWSER_EXTENSION,
      consistentIpForRequests: true,
    });
  }

  let bulder = buildProviders()
    .setFetcher(makeStandardFetcher(fetch))
    .setProxiedFetcher(makeLoadBalancedSimpleProxyFetcher())
    .setTarget(targets.BROWSER)
    .addSource(primewiresScraper);

  bulder = addEmbeds(bulder);
  bulder = addSources(bulder);

  return bulder.build();
}
