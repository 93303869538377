import { ScrapeContext } from "@movie-web/providers";
import { Base64 } from "js-base64";

// ---- 以下代码为直接、或反混淆后复制过来后修改而成的 ----

async function encox(e: string, t: string) {
  const o = new TextEncoder().encode(t);
  const i = await crypto.subtle.digest("SHA-256", o);

  const n = crypto.getRandomValues(new Uint8Array(12));
  const a = Array.from(n)
    .map((item) => String.fromCharCode(item))
    .join("");
  const s = { name: "AES-GCM", iv: n };
  const r = await crypto.subtle.importKey("raw", i, s, !1, ["encrypt"]);

  const c = new TextEncoder().encode(e);
  const l = await crypto.subtle.encrypt(s, r, c);

  const d = Array.from(new Uint8Array(l));
  const dMap = d.map((item) => String.fromCharCode(item)).join("");

  return btoa(a + dMap);
}

async function getIP(
  baseUrl: string,
  ctx: ScrapeContext,
): Promise<{ loc: string }> {
  const text: string = await ctx.proxiedFetcher(`${baseUrl}/cdn-cgi/trace`);

  const entries = text
    .trim()
    .split(
      `
`,
    )
    .map((i) => i.split("="));

  return Object.fromEntries(entries);
}

export async function getSRC(
  server: number,
  mid: string,
  episode: string,
  plyURL: string,
  ctx: ScrapeContext,
) {
  const response = await getIP(atob(plyURL), ctx);

  const loc = response.loc;
  const i = Math.floor(new Date().getTime() / 1e3);
  const a = Base64.encodeURI(
    await encox(`${mid}+${episode}+${server}+${loc}+${i}`, loc),
  );

  return `${atob(plyURL)}/watch/?v${server}${episode}#${a}`;
}

async function Q(uHash: string, loc: string) {
  const o = new TextEncoder().encode(loc);
  const l = await crypto.subtle.digest("SHA-256", o);

  const d = atob(uHash).slice(0, 12);
  const iv = new Uint8Array(Array.from(d).map((i) => i.charCodeAt(0)));
  const s = {
    name: "AES-GCM",
    iv,
  };
  const h = await crypto.subtle.importKey("raw", l, s, false, ["decrypt"]);

  const m = atob(uHash).slice(12);
  const f = new Uint8Array(Array.from(m).map((i) => i.charCodeAt(0)));
  try {
    const e = await crypto.subtle.decrypt(s, h, f);

    return new TextDecoder().decode(e);
  } catch {
    throw new Error("Decrypt failed");
  }
}

function u(hash: string) {
  const F = (e: string) => e.replace(/[^A-Za-z0-9+/]/g, "");
  const y = (e: string) =>
    F(e.replace(/[-_]/g, (substring) => (substring === "-" ? "+" : "/")));
  const T = (e: string) =>
    Uint8Array.from(
      atob(e)
        .split("")
        .map((i) => i.charCodeAt(0)),
    );

  return new TextDecoder().decode(T(y(hash)));
}

function v(p: Uint8Array | ArrayBuffer) {
  return (
    Array.prototype.slice
      .call(new Uint8Array(p))
      // eslint-disable-next-line no-bitwise
      .map((e) => [e >> 4, e & 15])
      .map((e) => e.map((i) => i.toString(16)).join(""))
      .join("")
  );
}

async function A(e: string): Promise<[CryptoKey, Uint8Array]> {
  const t = crypto.getRandomValues(new Uint8Array(8));
  const a = {
    name: "PBKDF2",
    salt: t,
    iterations: 1000,
    hash: "SHA-256",
  };
  const r = {
    name: "AES-GCM",
    length: 256,
  };

  let key = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(e),
    "PBKDF2",
    false,
    ["deriveKey"],
  );

  key = await crypto.subtle.deriveKey(a, key, r, false, ["encrypt", "decrypt"]);

  return [key, t];
}

async function se(param1: string, param2: string) {
  const r = crypto.getRandomValues(new Uint8Array(12));
  const l = new TextEncoder().encode(param2);
  const i = {
    name: "AES-GCM",
    iv: r,
  };

  const [e2, t1] = await A(param1);

  const e3 = await crypto.subtle.encrypt(i, e2, l);

  return `${v(t1)}-${v(r)}-${v(e3)}`;
}

export async function getM3u8(
  ctx: ScrapeContext,
  hash: string,
  realLocation: string,
) {
  const { loc } = await getIP(realLocation, ctx);

  const QString = await Q(u(hash), loc);

  const e = QString.trim().split("+");
  const s = Math.floor(new Date().getTime() / 1000);
  const seRusult = await se("player", `${e[0]}+${e[1]}+${e[2]}+${s}`);

  const { info }: { info: string } = await ctx.proxiedFetcher(
    `/get/${seRusult}`,
    {
      baseUrl: realLocation,
    },
  );

  return `${realLocation}/hls/${info}/master.m3u8`;
}
