import { create } from "zustand";

export interface TokenStore {
  token: string;
  setToken(t: string): void;
}

export const useTokenStore = create<TokenStore>((set) => ({
  token: "",
  setToken: (token) => set(() => ({ token })),
}));

export interface UserInfo {
  id: number;
  username: string;
  nickname: string;
  head: string;
}

export interface UserInfoStore {
  userInfo: UserInfo;
  setUserInfo(userInfo: UserInfo): void;
}

export const useUserInfoStore = create<UserInfoStore>((set) => ({
  userInfo: {
    id: 0,
    head: "/image/head.png",
    username: "name",
    nickname: "",
  },
  setUserInfo: (userInfo) => set(() => ({ userInfo })),
}));

export interface VipInfo {
  id: number;
  /** 0 - 默认值；1 - 正常；2 - 到期关闭 */
  stats: number;
  endTime: string;
  endDate: string;
}

export interface VipInfoStore {
  vipInfo: VipInfo;
  setVipInfo(vipInfo: VipInfo): void;
  /** 可以展示 vip, vpn 相关弹窗、信息 */
  canShowVip: boolean;
}

export const useVipInfoStore = create<VipInfoStore>((set) => ({
  vipInfo: {
    id: 0,
    stats: 0,
    endTime: "",
    endDate: "",
  },
  setVipInfo: (vipInfo) => set(() => ({ vipInfo })),
  canShowVip: (window as any)._SUBMIT_ !== "true",
}));
